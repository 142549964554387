import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isInvestor && _vm.Company.investorCompanyType < 2 && !_vm.loading
    ? _c(
        "div",
        [
          !_vm.dialog
            ? _c(
                "div",
                {
                  staticClass: "banner-bottom",
                  style: _vm.drawer && _vm.gs.isMobile() ? "display: none" : "",
                  attrs: {
                    "data-test": "Pipeline:AllocationBanner:Banner-Bottom",
                  },
                },
                [
                  _c(
                    VRow,
                    { staticClass: "text-part", attrs: { cols: "6", md: "8" } },
                    [
                      _vm.Company.investorCompanyType == 0
                        ? _c("div", [
                            _vm.Company.reservedQuotas === 100
                              ? _c("div", [
                                  _c("p", { staticClass: "dark-color" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("allocation_under_analysis")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      class: _vm.gs.isMobile() ? "mt-2" : "",
                                      staticStyle: {
                                        color: "var(--primary)",
                                        "font-weight": "700",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("reserved_quotas_briefing", {
                                              value: _vm.Company.reservedQuotas,
                                            })
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm.last == null || _vm.last.Status == 2
                              ? _c("div", [
                                  _c("p", { staticClass: "dark-color" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("reserve_allocation")) +
                                        " "
                                    ),
                                  ]),
                                  _vm.Company.reservedQuotas > 0
                                    ? _c(
                                        "p",
                                        {
                                          class: _vm.gs.isMobile()
                                            ? "mt-2"
                                            : "",
                                          staticStyle: {
                                            color: "var(--primary)",
                                            "font-weight": "700",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "reserved_quotas_briefing",
                                                  {
                                                    value:
                                                      _vm.Company
                                                        .reservedQuotas,
                                                  }
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm.last.Status == 1
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      "data-test":
                                        "Pipeline:AllocateBanner:PreviousAllocationConfirmed",
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "dark-color" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("allocation_confirmed", {
                                              value: _vm.formatToCurrency(
                                                _vm.last.Currency,
                                                _vm.last.Value
                                              ),
                                            })
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    attrs: {
                                      "data-test":
                                        "Pipeline:AllocateBanner:PreviousAllocationPending",
                                    },
                                  },
                                  [
                                    _vm.last.Status == 3
                                      ? _c("p", { staticClass: "dark-color" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "allocation_approval_pending",
                                                  {
                                                    value: _vm.formatToCurrency(
                                                      _vm.last.Currency,
                                                      _vm.last.Value
                                                    ),
                                                  }
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("p", { staticClass: "dark-color" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("allocation_pending", {
                                                  value: _vm.formatToCurrency(
                                                    _vm.last.Currency,
                                                    _vm.last.Value
                                                  ),
                                                })
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                ),
                          ])
                        : _c(
                            "div",
                            {
                              attrs: {
                                "data-test":
                                  "Pipeline:AllocationBanner:OutOfPreferences",
                              },
                            },
                            [
                              _vm.notifySent
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("team_notified")) +
                                        " "
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("not_highlight_banner")) +
                                        " "
                                    ),
                                  ]),
                            ]
                          ),
                    ]
                  ),
                  _vm.Company.reservedQuotas != 100
                    ? _c(
                        VRow,
                        {
                          staticClass: "btn-part",
                          attrs: { cols: "6", md: "4" },
                        },
                        [
                          _vm.last && _vm.last.Status == 3
                            ? _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.approvals },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "textButton",
                                      staticStyle: {
                                        color: "#7733ff",
                                        "text-decoration": "underline",
                                        "font-weight": "bold",
                                      },
                                      style: _vm.gs.isMobile()
                                        ? "font-size: 11px;"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("approvals")) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.investor_dashboard },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-0",
                                      staticStyle: {
                                        color: "var(--primary)",
                                        "text-decoration": "underline",
                                        "font-weight": "bold",
                                      },
                                      style: _vm.gs.isMobile()
                                        ? "font-size: 11px;"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("see_allocations")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                          !_vm.Company.briefing.preDeal &&
                          (_vm.user.ProfileDone == false ||
                            !_vm.checkComplyCube)
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "dxa-btn-primary mr-0",
                                  attrs: {
                                    "data-test":
                                      "Alpha:AllocateBanner:BtnCompleteRegistration",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleInvestorGoCompleteProfile(
                                        _vm.$route.path
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("complete_registration")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm.Company.investorCompanyType == 0
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "dxa-btn-primary mr-0",
                                  attrs: {
                                    "data-test":
                                      "Pipeline:AllocationBanner:BtnNewAllocation",
                                  },
                                  on: { click: _vm.book },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.last == null
                                            ? "allocate"
                                            : "new_allocation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm.Company.investorCompanyType == 1 &&
                              !_vm.notifySent
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "dxa-btn-primary mr-0",
                                  attrs: { loading: _vm.notifyLoading },
                                  on: { click: _vm.notify_team },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("notify_team")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("BriefingBookingConfirmationModal", {
                    attrs: {
                      InvestmentProxySigned: _vm.investmentProxySigned,
                      Investor: _vm.investor,
                      Company: _vm.Company,
                      Value: _vm.Value,
                      Notification: _vm.notification,
                    },
                    on: { close: _vm.reset_dialog, add: _vm.add_last },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }