import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.news_filtered.length > 0
    ? _c(
        VContainer,
        {
          staticClass: "dxa-briefing-card mt-4",
          style: _vm.gs.isMobile() ? "padding: 12px 16px !important" : "",
          attrs: { id: "market-news", fluid: "", tag: "section" },
        },
        [
          _c(
            "h4",
            {
              staticClass: "dxa-briefing-section-header h4",
              style: _vm.gs.isMobile() ? "padding-left: 12px !important" : "",
            },
            [_vm._v(" " + _vm._s(_vm.$t("market_news")) + " ")]
          ),
          _vm.news_filtered.length > 0
            ? _c("div", { staticClass: "pt-5" }, [
                !_vm.gs.isMobile()
                  ? _c("div", { staticClass: "dxa-briefing-media-row" }, [
                      _c("table", [
                        _c(
                          "tr",
                          _vm._l(_vm.news_filtered, function (n, i) {
                            return _c("td", { key: i }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirect_news(n)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      "object-fit": "contain",
                                      "max-height": "400px",
                                      "max-width": "450px",
                                    },
                                    attrs: {
                                      src: _vm.gs.get_photo_path(n.Image),
                                    },
                                  }),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.redirect_news(
                                _vm.news_filtered[_vm.index_selected]
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "object-fit": "contain",
                              "max-width": "100%",
                            },
                            attrs: {
                              src: _vm.gs.get_photo_path(
                                _vm.news_filtered[_vm.index_selected].Image
                              ),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        _vm._l(_vm.news_filtered, function (n, i) {
                          return _c("DXASelector", {
                            key: i,
                            attrs: { Checked: _vm.index_selected == i },
                            on: {
                              check: function ($event) {
                                _vm.index_selected = i
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      )
    : _vm.loading
    ? _c(
        VContainer,
        {
          staticClass: "white rounded mt-4",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("div", { staticClass: "ma-5" }, [
            _c(
              "h4",
              {
                staticClass: "dxa-briefing-section-header h4",
                style: _vm.gs.isMobile() ? "padding-left: 12px !important" : "",
              },
              [_vm._v(" " + _vm._s(_vm.$t("market_news")) + " ")]
            ),
          ]),
          _c(VProgressCircular, {
            staticStyle: { margin: "0 auto", display: "block" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }