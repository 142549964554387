import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4" }, [
    _c(
      "div",
      { staticClass: "mx-auto text-center" },
      [
        _c(
          VAvatar,
          { staticClass: "profile-image" },
          [
            _vm.Member.Image != null
              ? _c(VImg, {
                  staticStyle: { cursor: "pointer" },
                  attrs: { src: _vm.gs.get_photo_path(_vm.Member.Image) },
                })
              : _c(VImg, {
                  staticStyle: { cursor: "pointer" },
                  attrs: { src: require("@/assets/profile.png") },
                }),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "pt-2", staticStyle: { "font-size": "1.5rem" } },
          [_c("b", [_vm._v(_vm._s(_vm.Member.Name))])]
        ),
        _c("p", { staticStyle: { "font-size": "1.4rem" } }, [
          _vm._v(" " + _vm._s(_vm.gs.check_field(_vm.Member.Position)) + " "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }