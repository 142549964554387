import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "v-carousel-header" }, [
        _c("span", { staticStyle: { color: "var(--white)" } }, [
          _vm._v(_vm._s(_vm.model + 1) + "/" + _vm._s(_vm.Slides.length)),
        ]),
      ]),
      _c(
        VCarousel,
        {
          attrs: { "hide-delimiters": "", height: "100%" },
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        _vm._l(_vm.Slides, function (slide, i) {
          return _c(
            VCarouselItem,
            { key: i },
            [_c(VImg, { attrs: { src: slide } })],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }