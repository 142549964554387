import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.team.length > 0
    ? _c(
        VContainer,
        {
          staticClass: "dxa-briefing-card mt-4",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("div", [
            _c("h4", { staticClass: "dxa-briefing-section-header h4" }, [
              _vm._v(" " + _vm._s(_vm.$t("team")) + " "),
            ]),
            _vm.team.length > 0
              ? _c("div", [
                  !_vm.gs.isMobile()
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-6",
                          staticStyle: {
                            display: "inline-flex",
                            overflow: "auto",
                            width: "100%",
                          },
                        },
                        _vm._l(_vm.team, function (member, i) {
                          return _c(
                            "tr",
                            { key: i, staticClass: "mx-auto" },
                            [
                              _c("MemberComponent", {
                                attrs: { Member: member },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        [
                          _c("MemberComponent", {
                            attrs: { Member: _vm.team[this.index_selected] },
                          }),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(_vm.team, function (member, i) {
                              return _c("DXASelector", {
                                key: i,
                                attrs: { Checked: _vm.index_selected == i },
                                on: {
                                  check: function ($event) {
                                    _vm.index_selected = i
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm.loading
    ? _c(
        VContainer,
        {
          staticClass: "white rounded mt-4",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("div", { staticClass: "ma-5" }, [
            _c(
              "h4",
              {
                staticClass: "text-center h4",
                staticStyle: { color: "#006364" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("team")) + " ")]
            ),
          ]),
          _c(VProgressCircular, {
            staticStyle: { margin: "0 auto", display: "block" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }