import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    {
      staticClass: "dxa-briefing-card mt-4",
      style: _vm.gs.isMobile() ? "padding: 12px 4px 12px 4px !important" : "",
      attrs: { id: "company-presentation", fluid: "", tag: "section" },
    },
    [
      _c(
        "div",
        {
          staticClass: "ma-5",
          staticStyle: { "margin-top": "5px !important" },
        },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(
                "h4",
                {
                  staticClass: "dxa-briefing-section-header h4",
                  style: _vm.gs.isMobile()
                    ? "padding-left: 12px !important"
                    : "",
                },
                [_vm._v(" " + _vm._s(_vm.$t("company_presentation")) + " ")]
              ),
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { right: "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("fullscreen")
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { large: "", color: "primary" } }, [
                    _vm._v("mdi-fullscreen"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VContent,
            {
              staticClass: "mx-auto my-4",
              staticStyle: { padding: "0", "background-color": "var(--white)" },
            },
            [
              _c(
                "div",
                { staticClass: "thumb-example" },
                [
                  _c(
                    "swiper",
                    {
                      ref: "swiperTop",
                      staticClass: "swiper gallery-top",
                      attrs: { options: _vm.swiperOptionTop },
                    },
                    [
                      _vm._l(_vm.Slides, function (slide, i) {
                        return _c(
                          "swiper-slide",
                          { key: i },
                          [_c(VImg, { attrs: { src: slide } })],
                          1
                        )
                      }),
                      _c("div", {
                        staticClass: "swiper-button-next swiper-button-white",
                        attrs: { slot: "button-next" },
                        slot: "button-next",
                      }),
                      _c("div", {
                        staticClass: "swiper-button-prev swiper-button-white",
                        attrs: { slot: "button-prev" },
                        slot: "button-prev",
                      }),
                    ],
                    2
                  ),
                  _c(
                    "swiper",
                    {
                      ref: "swiperThumbs",
                      staticClass: "swiper gallery-thumbs",
                      attrs: { options: _vm.swiperOptionThumbs },
                    },
                    _vm._l(_vm.Slides, function (slide, i) {
                      return _c(
                        "swiper-slide",
                        { key: i },
                        [_c(VImg, { attrs: { src: slide } })],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }