import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=2d6f1302&scoped=true&"
import script from "./Team.vue?vue&type=script&lang=js&"
export * from "./Team.vue?vue&type=script&lang=js&"
import style0 from "./Team.vue?vue&type=style&index=0&id=2d6f1302&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d6f1302",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d6f1302')) {
      api.createRecord('2d6f1302', component.options)
    } else {
      api.reload('2d6f1302', component.options)
    }
    module.hot.accept("./Team.vue?vue&type=template&id=2d6f1302&scoped=true&", function () {
      api.rerender('2d6f1302', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/company/briefing/children/Team/Team.vue"
export default component.exports