import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    {
      staticClass: "mx-auto",
      staticStyle: {
        position: "relative",
        "min-width": "40%",
        "max-width": "530px",
      },
    },
    [
      _c(
        VIcon,
        {
          staticStyle: { position: "absolute", right: "10px", top: "10px" },
          attrs: { color: _vm.last == null && _vm.step == 1 && "primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("mdi-close")]
      ),
      _c(VCardText, [
        !_vm.allowed && !_vm.Company.briefing.preDeal
          ? _c(
              "div",
              [
                _c("h5", {
                  staticClass: "text-center h5 mb-3",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("booking_modal_text", { name: _vm.user.fullName })
                    ),
                  },
                }),
                _c(
                  VCardActions,
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "dxa_modal_btnSuccess",
                        attrs: {
                          color: "primary",
                          "min-width": "100",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/profile/complete")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("complete_registration")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.kyc && _vm.kyc.result == 2
          ? _c(
              "div",
              [
                _c("h5", { staticClass: "text-center h5 mb-3" }, [
                  _vm._v(" " + _vm._s(_vm.$t("bc_rejected")) + " "),
                ]),
                _c(
                  VCardActions,
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "dxa_modal_btnSuccess",
                        attrs: { color: "red", "min-width": "100" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("close")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.last == null && _vm.step == 1
          ? _c(
              "div",
              [
                _c(
                  "h5",
                  {
                    staticClass:
                      "text-center h5 confirm-request-allocation-title",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("booking_conf_title")) + " ")]
                ),
                _c(
                  VForm,
                  {
                    ref: "form",
                    attrs: { "lazy-validation": _vm.lazy },
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.confirm_value($event)
                      },
                    },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "confirm-request-allocation-grid" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "confirm-request-allocation-select-currency-container",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "confirm-request-allocation-label",
                              },
                              [_vm._v(_vm._s(_vm.$t("currency")))]
                            ),
                            _c(VSelect, {
                              staticClass: "my-input-style",
                              attrs: {
                                rules: [_vm.required],
                                items: _vm.currencyOptions,
                                "item-text": "text",
                                "item-value": "value",
                                outlined: "",
                                dense: "",
                                color: "primary",
                                "data-test":
                                  "Pipeline:BookingConfirmation:SelectCurrency",
                              },
                              on: { change: _vm.get_min_allocation_value },
                              model: {
                                value: _vm.currency,
                                callback: function ($$v) {
                                  _vm.currency = $$v
                                },
                                expression: "currency",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "confirm-request-allocation-money-currency",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "confirm-request-allocation-label",
                              },
                              [_vm._v(_vm._s(_vm.$t("amount")))]
                            ),
                            _c("money", {
                              staticClass: "money-input my-input-style",
                              staticStyle: { color: "var(--dark)" },
                              attrs: {
                                rules: [
                                  function (v) {
                                    return (
                                      v >= _vm.allocationMinValue ||
                                      _vm.required()
                                    )
                                  },
                                ],
                                color: "primary",
                                dense: "",
                                outlined: "",
                                thousands: ".",
                                decimal: ",",
                                "data-test":
                                  "Pipeline:BookingConfirmation:InputAllocationValue",
                                "data-min-value": "" + _vm.allocationMinValue,
                              },
                              nativeOn: {
                                change: function ($event) {
                                  return _vm.showAlertMessageMinValue()
                                },
                              },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            }),
                            _vm.showNotEnoughMessage
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "min-value-required-message",
                                    attrs: {
                                      "data-test":
                                        "Pipeline:BookingConfirmation:MsgNotEnough",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("not_enough", {
                                          value: _vm.formatCurrency(
                                            _vm.currency,
                                            _vm.allocationMinValue
                                          ),
                                        })
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "person-options-container" }, [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "span",
                            { staticClass: "confirm-request-allocation-label" },
                            [_vm._v(_vm._s(_vm.$t("investment_desire")))]
                          ),
                          _c(VSelect, {
                            staticClass: "my-input-style",
                            attrs: {
                              placeholder: "Selecionar",
                              rules: [_vm.required],
                              items: _vm.personOptions,
                              "item-text": "tradingName",
                              "item-value": "id",
                              outlined: "",
                              dense: "",
                              color: "primary",
                              "data-test":
                                "Pipeline:BookingConfirmation:SelectPersonOption",
                            },
                            model: {
                              value: _vm.personId,
                              callback: function ($$v) {
                                _vm.personId = $$v
                              },
                              expression: "personId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "confirm-allocation-min-value-content" },
                      [
                        _vm.allocationMinValue > 0
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("confirm_allocation_min_value", {
                                      minValue: _vm.formatCurrency(
                                        _vm.currency,
                                        _vm.allocationMinValue
                                      ),
                                    })
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("confirm_allocation_min_value_text")
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      VBtn,
                      {
                        attrs: {
                          loading: _vm.loading,
                          type: "submit",
                          id: "confirm-allocation-button-submit",
                          "data-test":
                            "Pipeline:BookingConfirmation:BtnConfirmValue",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("confirm_allocation_button_title")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.last == null && _vm.step == 2
          ? _c(
              "div",
              [
                _c(
                  VForm,
                  {
                    ref: "form",
                    attrs: { "lazy-validation": _vm.lazy },
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.next_step($event)
                      },
                    },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c(
                      "h5",
                      { staticClass: "text-center h5 mb-3 dark-color" },
                      [_vm._v(" " + _vm._s(_vm.$t("commitment_term")) + " ")]
                    ),
                    _vm.Company.commitmentTermText != null
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "max-height": "310px",
                              overflow: "auto",
                            },
                          },
                          [
                            _c("p", {
                              staticClass: "dark-color",
                              staticStyle: { color: "var(--dark)" },
                              domProps: {
                                innerHTML: _vm._s(
                                  (_vm.gs.isJson(_vm.Company.commitmentTermText)
                                    ? JSON.parse(
                                        _vm.Company.commitmentTermText
                                      )[_vm.$i18n.locale]
                                    : _vm.Company.commitmentTermText
                                  ).replace(
                                    "${Valor}",
                                    _vm.formatCurrency(_vm.currency, _vm.value)
                                  )
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c(VCheckbox, {
                      attrs: {
                        rules: [_vm.required],
                        "data-test": "Notification:BookingModal:CheckboxAgree",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [_vm._v(_vm._s(_vm.$t("agree_terms")))]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.terms_agreed,
                        callback: function ($$v) {
                          _vm.terms_agreed = $$v
                        },
                        expression: "terms_agreed",
                      },
                    }),
                    _c(
                      VCardActions,
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "dxa_modal_btnSuccess mx-auto",
                            attrs: {
                              color: "secondary",
                              loading: _vm.loading,
                              "min-width": "100",
                              type: "submit",
                              "data-test":
                                "Notification:BookingModal:BtnConfirm",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("allocate")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : !_vm.InvestmentProxySigned &&
            _vm.step == 3 &&
            !_vm.Company.briefing.preDeal
          ? _c(
              "div",
              [
                _c("h5", { staticClass: "text-center h5 mb-3" }, [
                  _vm._v(" " + _vm._s(_vm.$t("investment_proxy")) + " "),
                ]),
                _c("p", { staticClass: "mx-6" }, [
                  _vm._v(" " + _vm._s(_vm.$t("investment_proxy_text")) + " "),
                ]),
                _c(
                  VCardActions,
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "dxa_modal_btnSuccess mx-auto",
                        attrs: {
                          color: "secondary",
                          outlined: "",
                          loading: _vm.loading,
                          "min-width": "100",
                          "data-test":
                            "Pipeline:BookingConfirmation:BtnNextProxy",
                        },
                        on: { click: _vm.getProxy },
                      },
                      [_vm._v(_vm._s(_vm.$t("next")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : !_vm.InvestmentProxySigned &&
            _vm.step == 4 &&
            !_vm.Company.briefing.preDeal
          ? _c(
              "div",
              [
                _c(
                  VForm,
                  {
                    ref: "form",
                    attrs: { "lazy-validation": _vm.lazy },
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.book($event)
                      },
                    },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("h5", { staticClass: "text-center h5 mb-3" }, [
                      _vm._v(" " + _vm._s(_vm.$t("investment_proxy")) + " "),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "310px",
                          overflow: "auto",
                        },
                      },
                      [
                        _c("p", { staticClass: "dark-color" }, [
                          _vm.Investor.JuridicalType ==
                            _vm.JuridicalTypeEnum.NaturalPerson &&
                          _vm.Investor.Nationality != null &&
                          _vm.Investor.Nationality != "Brazil"
                            ? _c("span", [
                                _vm._v(
                                  "GRANTOR: " +
                                    _vm._s(
                                      _vm.Investor.Name +
                                        " " +
                                        _vm.Investor.LastName
                                    ) +
                                    ", " +
                                    _vm._s(_vm.Investor.Nationality) +
                                    ", " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.maritalStatus[
                                          _vm.Investor.MaritalStatus
                                        ]
                                      )
                                    ) +
                                    ", " +
                                    _vm._s(
                                      _vm.gs.convert_date(_vm.Investor.Birth)
                                    ) +
                                    ", " +
                                    _vm._s(_vm.Investor.Profession) +
                                    ", bearer of identity card " +
                                    _vm._s(_vm.Investor.DocumentNumber) +
                                    ", issued by the " +
                                    _vm._s(_vm.Investor.Issuer) +
                                    "/" +
                                    _vm._s(_vm.Investor.IssuerState) +
                                    ", registered with the Passaport under No. " +
                                    _vm._s(_vm.Investor.SocialNumber) +
                                    ", resident at " +
                                    _vm._s(_vm.Investor.Address) +
                                    ", No. " +
                                    _vm._s(_vm.Investor.AddressNumber) +
                                    ", district " +
                                    _vm._s(_vm.Investor.Neighborhood) +
                                    ", in " +
                                    _vm._s(_vm.Investor.City) +
                                    "/" +
                                    _vm._s(_vm.Investor.State) +
                                    ", Zip code " +
                                    _vm._s(_vm.Investor.PostCode) +
                                    "."
                                ),
                              ])
                            : _vm.Investor.JuridicalType ==
                              _vm.JuridicalTypeEnum.NaturalPerson
                            ? _c("span", [
                                _vm._v(
                                  "OUTORGANTE: " +
                                    _vm._s(
                                      _vm.Investor.Name +
                                        " " +
                                        _vm.Investor.LastName
                                    ) +
                                    ", " +
                                    _vm._s(_vm.Investor.Nationality) +
                                    ", " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.maritalStatus[
                                          _vm.Investor.MaritalStatus
                                        ]
                                      )
                                    ) +
                                    ", " +
                                    _vm._s(
                                      _vm.gs.convert_date(_vm.Investor.Birth)
                                    ) +
                                    ", " +
                                    _vm._s(_vm.Investor.Profession) +
                                    ", portador da cédula de identidade " +
                                    _vm._s(_vm.Investor.DocumentNumber) +
                                    ", expedida pelo " +
                                    _vm._s(_vm.Investor.Issuer) +
                                    "/" +
                                    _vm._s(_vm.Investor.IssuerState) +
                                    ", inscrito no CPF sob o nº " +
                                    _vm._s(_vm.Investor.SocialNumber) +
                                    ", residente e domiciliado na " +
                                    _vm._s(_vm.Investor.Address) +
                                    ", nº " +
                                    _vm._s(_vm.Investor.AddressNumber) +
                                    ", bairro " +
                                    _vm._s(_vm.Investor.Neighborhood) +
                                    ", em " +
                                    _vm._s(_vm.Investor.City) +
                                    "/" +
                                    _vm._s(_vm.Investor.State) +
                                    ", CEP " +
                                    _vm._s(_vm.Investor.PostCode) +
                                    "."
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "OUTORGANTE: " +
                                    _vm._s(_vm.Investor.CompanyName) +
                                    ", inscrita no CNPJ sob o nº " +
                                    _vm._s(_vm.Investor.SocialNumber) +
                                    ", com sede na " +
                                    _vm._s(_vm.Investor.Address) +
                                    ", nº " +
                                    _vm._s(_vm.Investor.AddressNumber) +
                                    ", bairro " +
                                    _vm._s(_vm.Investor.Neighborhood) +
                                    ", em " +
                                    _vm._s(_vm.Investor.City) +
                                    "/" +
                                    _vm._s(_vm.Investor.State) +
                                    ", CEP " +
                                    _vm._s(_vm.Investor.PostCode) +
                                    ", neste ato representada na forma de seus atos constitutivos. "
                                ),
                              ]),
                        ]),
                        _vm.Investor.JuridicalType ==
                          _vm.JuridicalTypeEnum.NaturalPerson &&
                        _vm.Company.investmentProxyPFText
                          ? _c("p", {
                              staticClass: "dark-color",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.gs.isJson(
                                    _vm.Company.investmentProxyPFText
                                  )
                                    ? JSON.parse(
                                        _vm.Company.investmentProxyPFText
                                      )[_vm.$i18n.locale]
                                    : _vm.Company.investmentProxyPFText
                                ),
                              },
                            })
                          : _vm.Company.investmentProxyPJText
                          ? _c("p", {
                              staticClass: "dark-color",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.gs.isJson(
                                    _vm.Company.investmentProxyPJText
                                  )
                                    ? JSON.parse(
                                        _vm.Company.investmentProxyPJText
                                      )[_vm.$i18n.locale]
                                    : _vm.Company.investmentProxyPJText
                                ),
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(VCheckbox, {
                      attrs: {
                        rules: [_vm.required],
                        "data-test":
                          "Pipeline:BookingConfirmation:BtnAgreeWithProxy",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [_vm._v(_vm._s(_vm.$t("agree_terms")))]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.investment_agreed,
                        callback: function ($$v) {
                          _vm.investment_agreed = $$v
                        },
                        expression: "investment_agreed",
                      },
                    }),
                    _c(
                      VCardActions,
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "dxa_modal_btnSuccess mx-auto",
                            attrs: {
                              color: "secondary",
                              loading: _vm.loading,
                              "min-width": "100",
                              type: "submit",
                              "data-test":
                                "Pipeline:BookingConfirmation:BtnSignProxy",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("sign_investment_proxy")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }